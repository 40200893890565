const tour = 'https://wine85.s3.eu-central-1.amazonaws.com/tour.jpg';
const tour1 = 'https://wine85.s3.eu-central-1.amazonaws.com/tour1.jpg';
const tour2 = 'https://wine85.s3.eu-central-1.amazonaws.com/tour2.jpg';
const tour3 = 'https://wine85.s3.eu-central-1.amazonaws.com/tour3.jpg';

export const tours = {
  en: {
    1: {
      img: tour,
      title: 'Standard',
      price: '6000',
      text: 'JRAGHATSPANYAN CLASSIC DRY WHITE, ROSE, RED',
      description: 'DISHES CHEESE, MEAT, DRY FRUITS AND STICKS',
    },
    2: {
      img: tour1,
      title: 'Standard +',
      price: '20000',
      text: 'Jraghatspanyan white, rose, red classic dry',
      description: 'DISHES Cheese, meat, dry FRUITS and sticks + Dinner or supper',
    },
    3: {
      img: tour2,
      title: 'SPECIAL',
      price: '10000',
      text: 'Jraghatspanyan white, rose, red classic dry, Special selection Garan Dmak white dry, Special selection Tigrani red dry ',
      description: 'DISHES CHEESE, MEAT, DRY FRUITS AND STICKS',
    },
    4: {
      img: tour3,
      title: 'SPECIAL +',
      price: '15000',
      text: 'Jraghatspanyan white, rose, red classic dry, Special selection Garan Dmak white dry, Special selection Tigrani red dry',
      description: 'DISHES Cheese, meat, dry FRUITS and sticks + Dinner or supper',
    },
    5: {
      img: tour2,
      title: 'Premium',
      price: '14000',
      text: 'Jraghatspanyan white, rose, red classic dry, Special selection Garan Dmak white dry Special selection Tigrani red dry, Jraghatspanyan Reserve Reserved red dry',
      description: 'DISHES CHEESE, MEAT, DRY FRUITS AND STICKS',
    },
    6: {
      img: tour3,
      title: 'Premium +',
      price: '20000',
      text: 'Jraghatspanyan white, rose, red classic dry, Special selection Garan Dmak white dry, Special selection Tigrani red dry, Jraghatspanyan Reserve Reserved red dry',
      description: 'DISHES CHEESE, MEAT, DRY FRUITS AND STICKS + Dinner or supper',
    },
  },
  am: {
    1: {
      img: tour,
      title: 'Standard',
      price: '6000',
      text: 'Համտեսվող գինիներն են Ջրաղացպանյան սպիտակ, վարդագույն և կարմիր անապակ',
      description: 'Ուտեստներ Պանրի, մսի, չրերի տեսականի և չորահաց',
    },
    2: {
      img: tour1,
      title: 'Standard +',
      price: '10.000',
      text: 'Համտեսվող գինիներն են Ջրաղացպանյան սպիտակ, վարդագույն և կարմիր անապակ',
      description: 'Ուտեստներ Պանրի, մսի, չրերի տեսականի և չորահաց + Ճաշ կամ ընթրիք',
    },
    3: {
      img: tour2,
      title: 'Special',
      price: '10.000',
      text: 'Համտեսվող գինիներն են Ջրաղացպանյան Սպիտակ, վարդագույն և կարմիր անապակ, Ջրաղացպանյան Հատուկ Ընտրովի Գառան Դմակ և Տիգրանի',
      description: 'Ուտեստներ Պանրի, մսի, չրերի տեսականի և չորահաց',
    },
    4: {
      img: tour3,
      title: 'Special +',
      price: '15000',
      text: 'Համտեսվող գինիներն են Ջրաղացպանյան Սպիտակ, վարդագույն և կարմիր անապակ, Ջրաղացպանյան Հատուկ Ընտրովի Գառան Դմակ և Տիգրանի',
      description: 'Ուտեստներ Պանրի, մսի, չրերի տեսականի և չորահաց + Ճաշ կամ ընթրիք',
    },
    5: {
      img: tour2,
      title: 'Premium',
      price: '14000',
      text: 'Համտեսվող գինիներն են Ջրաղացպանյան Սպիտակ, վարդագույն և կարմիր անապակ Ջրաղացպանյան Հատուկ Ընտրովի Գառան Դմակ և Տիգրանի Ջրաղացպանյան ռեզերվ Կարմիր հնեցված անապակ',
      description: 'Ուտեստներ Պանրի, մսի, չրերի տեսականի և չորահաց',
    },
    6: {
      img: tour3,
      title: 'Premium +',
      price: '20000',
      text: 'Համտեսվող գինիներն են Ջրաղացպանյան Սպիտակ, վարդագույն և կարմիր անապակ Ջրաղացպանյան Հատուկ Ընտրովի Գառան Դմակ և Տիգրանի Ջրաղացպանյան ռեզերվ Կարմիր հնեցված անապակ',
      description: 'Ուտեստներ Պանրի, մսի, չրերի տեսականի և չորահաց + Ճաշ կամ ընթրիք',
    },
  },
  ru: {
    1: {
      img: tour,
      title: 'СТАНДАРТ',
      price: '6000',
      text: 'Дегустируемые вина Джрагацпанян белое сухое классическое розовое сухое классическое красное сухое классическое',
      description: 'Еда Ассортимент мяса, сыров, сухофруктов и сухарики.',
    },
    2: {
      img: tour1,
      title: 'СТАНДАРТ +',
      price: '10000',
      text: 'Дегустируемые вина Джрагацпанян белое сухое классическое розовое сухое классическое красное сухое классическое',
      description: 'Еда Ассортимент мяса, сыров, сухофруктов и сухарики + Обед или ужин',
    },
    3: {
      img: tour2,
      title: 'СПЕЦИАЛЫНЫЙ',
      price: '10000',
      text: 'Дегустируемые вина Джрагацпанян белое сухое классическое розовое сухое классическое красное сухое классическое Специалыно отборный Гаран Дмак белое сухое Специалыно отборный Тиграни красное сухое',
      description: 'Еда Ассортимент мяса, сыров, сухофруктов и сухарики.',
    },
    4: {
      img: tour3,
      title: 'СПЕЦИАЛЫНЫЙ +',
      price: '15000',
      text: 'Дегустируемые вина Джрагацпанян белое сухое классическое розовое сухое классическое красное сухое классическое Специалыно отборный Гаран Дмак белое сухое Специалыно отборный Тиграни красное сухое',
      description: 'Еда Ассортимент мяса, сыров, сухофруктов и сухарики + Обед или ужин',
    },
    5: {
      img: tour2,
      title: 'ПРЕМИУМ',
      price: '14000',
      text: 'Дегустируемые вина Джрагацпанян белое, розовое, красноесухие классические Специалыно отборный Гаран Дмак белое сухое Специалыно отборный Тиграни красное сухое Джрагацпанян резерв красное сухое',
      description: 'Еда Ассортимент мяса, сыров + сухофруктов и сухарики.',
    },
    6: {
      img: tour3,
      title: 'ПРЕМИУМ +',
      price: '20000',
      text: 'Дегустируемые вина Джрагацпанян белое, розовое, красноесухие классические Специалыно отборный Гаран Дмак белое сухое Специалыно отборный Тиграни красное сухое Гости- 4 и более персон Джрагацпанян резерв красное сухое',
      description: 'Еда Ассортимент мяса, сыров, сухофруктов и сухарики + Обед или ужин',
    },
  }
}
